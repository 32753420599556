import StylesProductServiceInterface
  from '~/src/pages/category/[slug]/product/[slug]/styles/StylesProductServiceInterface'
import getProductsByCategoryTemplateService
  from '~/src/pages/category/[slug]/products/styles/GetProductsByCategoryTemplateService'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetProductsByCategoryTemplate = (): StylesProductServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('category')

  useLayout('category')

  return getProductsByCategoryTemplateService(pageStyle, muiTheme)
}

export default useGetProductsByCategoryTemplate
