import { Category } from '@eo-storefronts/eo-core'
import CategoryDetail from '~/src/components/category/CategoryDetail'

interface Props {
  category: Category,
}

const CategoryContainer = ({ category }: Props) => {
  if (category.products?.length) {
    return <CategoryDetail categoryId={category.id} />
  }

  return (
    <>
      {category.subcategories.map((catId: string) => (
        <CategoryDetail categoryId={catId} key={catId} />
      ))}
    </>
  )
}

export default CategoryContainer
