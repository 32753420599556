import { Box } from '@mui/material'
import { useEffect } from 'react'
import CategoryContainer from '~/src/components/category/category-container'
import InPageLoader from '~/src/components/loader/InPageLoader'
import useGetProductsByCategoryTemplate
  from '~/src/pages/category/[slug]/products/styles/useGetProductsByCategoryTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import useLogViewItemList from '~/src/hooks/analytics/ecommerce/useLogViewItemList'
import useCheckCategoryEffect from '~/src/hooks/categories/useCheckCategoryEffect'
import { useEoValue } from '~/src/hooks/useEoState'
import { CATEGORY_BY_ID_SELECTOR } from '~/src/stores/categories'
import { PRODUCTS_FROM_CATEGORY_SELECTOR } from '~/src/stores/product'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'
import { Constants } from '~/src/helpers/constants'

const ProductsByCategories = () => {
  const pageName: keyof typeof RoutesEnum = 'MENU'
  const { log: logViewItemList } = useLogViewItemList()
  const category = useEoValue(CATEGORY_BY_ID_SELECTOR)
  const productsFromCategory = useEoValue(PRODUCTS_FROM_CATEGORY_SELECTOR)
  const styles = useGetProductsByCategoryTemplate()

  useCheckCategoryEffect(category?.id)

  useEffect(() => {
    if (category?.products) {
      logViewItemList(
        productsFromCategory,
        category
      )
    }
  }, [ category ])


  if (!category) {
    return <InPageLoader/>
  }

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5,
        delay: Constants.MOTION_ANIMATION_DELAY
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>
      <Box sx={styles.getPageSx()}>
        <CategoryContainer category={category}/>
      </Box>
    </SlideFromRight>
  )
}

export default ProductsByCategories
