import { Category, Product } from '@eo-storefronts/eo-core'
import useLogAnalyticEcommerceEvent from '~/src/hooks/analytics/useLogAnalyticEcommerceEvent'

interface ReturnsType {
  log(products: Array<Product>, category?: Category): void,
}

const useLogViewItemList = (): ReturnsType => {
  const {
    logGoogleEvent: logGoogleAnalyticEvent,
    logFacebookEvent: logFacebookAnalyticEvent
  } = useLogAnalyticEcommerceEvent()

  const log = (products: Array<Product>, category?: Category): void => {
    logGoogleAnalyticEvent('view_item_list', {
      items: products,
      item_list_id: category?.id,
      item_list_name: category?.name
    })
    logFacebookAnalyticEvent('ViewContent', {
      content_ids: products.map((product: Product) => product.id.toString()),
      content_type: 'product_group',
      contents: products
    })
  }

  return { log }
}

export default useLogViewItemList
