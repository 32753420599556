import { Id } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { CATEGORY_SHOULD_BE_DISPLAY_SELECTOR } from '~/src/stores/categories'
import { useEffect } from 'react'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

const useCheckCategoryEffect = (categoryId: Id | undefined | null) => {
  const categoryShouldBeDisplay = useEoValue(CATEGORY_SHOULD_BE_DISPLAY_SELECTOR(categoryId))
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()

  useEffect(() => {
    if (!categoryId) {
      return
    }

    if (!categoryShouldBeDisplay) {
      push(resolve(RoutesEnum.FIRM_HOME))
    }
  }, [ categoryId, checkoutForm.delivery_method.time ])
}

export default useCheckCategoryEffect
