import { SxProps } from '@mui/material'
import StylesProductServiceInterface
  from '~/src/pages/category/[slug]/product/[slug]/styles/StylesProductServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesProductsByCategoryServiceStyleTwo extends TemplateServiceStyleBase implements StylesProductServiceInterface {
  public getPageSx(): SxProps {
    return {
      pb: this.muiTheme.spacing(6),
      [this.muiTheme.breakpoints.down('md')]: {
        pb: this.muiTheme.spacing(2)
      }
    }
  }
}
