import { SxProps } from '@mui/material'
import StylesProductServiceInterface
  from '~/src/pages/category/[slug]/product/[slug]/styles/StylesProductServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesProductsByCategoryServiceStyleOne extends TemplateServiceStyleBase implements StylesProductServiceInterface {
  public getPageSx(): SxProps {
    return {
      pl: 4,
      pb: 4,
      [this.muiTheme.breakpoints.down('md')]: {
        pl: 0,
        pb: 2
      }
    }
  }
}
