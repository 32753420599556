import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesProductsByCategoryServiceInterface
  from '~/src/pages/category/[slug]/products/styles/StylesProductsByCategoryServiceInterface'
import StylesProductsByCategoryServiceStyleTwo
  from '~/src/pages/category/[slug]/products/styles/StylesProductsByCategoryServiceStyleTwo'
import StylesProductsByCategoryServiceStyleOne
  from '~/src/pages/category/[slug]/products/styles/StylesProductsByCategoryServiceStyleOne'
import StylesProductsByCategoryServiceStyleFour
  from '~/src/pages/category/[slug]/products/styles/StylesProductsByCategoryServiceStyleFour'

const getProductsByCategoryTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesProductsByCategoryServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_FOUR:
      return new StylesProductsByCategoryServiceStyleFour(muiTheme)
    case TemplateEnum.STYLE_TWO:
      return new StylesProductsByCategoryServiceStyleTwo(muiTheme)
    default:
      return new StylesProductsByCategoryServiceStyleOne(muiTheme)
  }
}

export default getProductsByCategoryTemplateService
